.smooth-appear {
  -webkit-animation: 1.5s ease 0s normal forwards 1 fadein;
  animation: 1.5s ease 0s normal forwards 1 fadein;
}

@keyframes fadein{
  0% { opacity:0; }
  66% { opacity:0; }
  100% { opacity:1; }
}

@-webkit-keyframes fadein{
  0% { opacity:0; }
  66% { opacity:0; }
  100% { opacity:1; }
}