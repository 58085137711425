.mobile-menu-tabs{
    position: fixed;
    bottom: 55px;
    width: 100%;
    left: 0;
    background: white;
    z-index: 10;
    margin-bottom: 24px;
    border-bottom:1px solid #EAECEE;
}

.mobile-menu-tabs .MuiTabs-flexContainer{
    width: 100%;
    display: flex;
    justify-content: space-around;

}
